<template>
    <div class="row">
            <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
        <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none d-md-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>READING: Multiple Choice (Single)
            </h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists" :questionId="'mcs_id'"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion">
            <h4 class="text-center mb-4 fancy"><span>READING: Multiple Choice (Single)</span></h4>             
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">You'll get text, read that text and answer the multiple-choice question by selecting the correct response. Only one response is correct.</p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Reading</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 2 - 3 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>You will get a passage of upto 110 words on screen. You need to go through the text. Below the text you will get a question with multiple options. You need to select only one which would be the correct answer.</li>
                <li>Read the question and find out the points to focus. Have a look at the question and the options, start reading the text. Choose the best acceptable option.</li>
                <li>On an average, you should not be spending more than 2 minutes on each question.</li>
            </ul>
            </template>
            <app-ReadingExtraInfo />
        </div>
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" :class="{'col-12':(questId != 0)}" v-if="selectedquestion">
             <h4>Multiple Choice (Single)</h4>
            <p class="questHeading">Read the text and answer the multiple-choice question by selecting the correct response. Only one response is correct.</p>
            <div class="row mb-2">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                <div class="prepare" ><p>Time </p><app-Timer ref="timer" :increment="true"/></div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0" >
            <app-QuestionStatus ref="QuestionResetStatus" :selectedQuestion="selectedquestion" :question_id="selectedquestion.mcs_id" :idName="'mcs_id'" :tableName="'tblMultipleChioce'"/>
            </div>
            </div>
            
            <p class="questdesc">{{selectedquestion.msc_desc}}</p>
            <p class="questionMark">{{selectedquestion.msc_question}}</p>
            <ol class="mt-3">
                <li v-for="item in answerLists" :key="item.mcsc_id" :id="item.mcsc_id" class="mt-3">
                <label>
                    <input type="radio"
                    v-on:change="toggle(item)"
                    v-bind:checked="item.done">

                    <span v-if="item.done">
                    {{ item.mcsc_options }}
                    </span>
                    <span v-else>
                    {{ item.mcsc_options }}
                    </span>
                </label>
                </li>
            </ol>

            <div class="row">
                <div class="col-12 text-left mt-4" style="background: #001848;" v-if="answerSubmit">
                    <p style="padding-left:0px!important;color:#fff;"><strong>Your Score:   <span class="pl-1">{{score_count}}</span>/1</strong></p>
                </div>
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5 pl-0">
                    <b-button class="mr-2 pl-4 pr-4" variant="outline-info" @click="submitanswer()">Submit</b-button>
                    <b-button variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <b-button variant="outline-dark" class="mr-2 pl-4 pr-4" @click="showModal()">Dict Mode</b-button>
                    <toggle-button :width="75" :height="28" v-model="checkAnswerFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Answer', unchecked: 'Answer'}"/>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5">
                    <div class="row">
                        <div class="col-2 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input v-model="searchKey" type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="searchQuestion()" variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-2 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button @click="$refs.intervalList.changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center mt-4" v-if="checkAnswerFlag">
                <h4 class="decorated" style="text-transform:capitalize;"><span>Answer</span></h4>
                    <div class="col-12 mb-2 mt-2 results text-left" >
                    <h5 style="text-transform:capitalize;">Transcript:- </h5>
                    <p style="display:block;padding-bottom: 0px !important;">
                        <ul >
                            <li class="rightAnswer"><strong> {{actualAnswer.mcsc_options}}</strong></li>
                        </ul>
                    </p>
                    </div>
                </div>
            </div>

            <!-- <div class="row">
                <div class="col-12 mb-4 results" v-if="checkAnswerFlag == true">
                    <h5>Result:- </h5>
                    <p><strong>Your result is {{userAnswer.user_score}} out of 1</strong></p>
                </div>
                <div class="col-12 text-center">
                    <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-5 prevQuestion">Prev Question</b-button>
                    <b-button variant="success" @click="submitanswer()">Find Answer & Marks</b-button>
                    <b-button @click="$refs.intervalList.changeQuestion('next')" class="ml-5 nextQuestion">Next Question</b-button>
                </div>
            </div> -->
        </div>
        <b-modal ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
             <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'mcs_id'"/></b-container>
        </b-modal>
        <b-modal id="dictionery-Modal" size="lg" scrollable centered hide-footer hide-header @hidden="hideModal" ok-only ok-title="Cancel" ok-variant="secondary">
         <b-container class="" v-if="selectedquestion!=null">
              <app-dictTemp :templatePara="dictText" :title="modelTitle"/>
         </b-container>
        </b-modal>
        <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tblUserMCSAnswer'"/>
        </div> 
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import Timer from '../reuseable/timer'
import $ from "jquery";
import IntervalList from '../reuseable/intervalList'
import QuestionStatus from '../reuseable/questStatus'
import dictTemplate from '../dictionerymode/dictioneryTemplate'
import DiscussionTab from '../reuseable/discussionTab'
import ReadingExtraInfo from '../reuseable/readingInformation.vue'
//import LocalStorageService from '../../store/LocalStorageService';
export default {
    components:{
        'app-Timer':Timer,
        'app-IntervalList': IntervalList,
        'app-dictTemp': dictTemplate,
        'app-QuestionStatus':QuestionStatus,
        'app-DiscussionTab':DiscussionTab,
        'app-ReadingExtraInfo':ReadingExtraInfo
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            selectedquestion: null,
            answerLists:{
                type: Object,
                required: true
            },
            correctAnswer:null,
            currentAnswer: null,
            userAnswerResponse:null,
            userAnswer:{
                mcs_id:0,
                user_id:0,
                mcsc_id:0,
                url:'',
                mock_test_id:0,
                user_score:'' //if 0 then answer is not correct if 1 then answer is correct
            },
            score_count:0,
            checkAnswerFlag: false,
            user_type:'Free',
            currentQuestion:0,
            actualAnswer:null,
            answerSubmit:false,
            searchKey:'',
            searchQuestionList:null,
            isShowModal: false,
            modelTitle:'heading',
            dictText:''
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'readingmodule/fetchQuestion',
            saveAnswer: 'writtingmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'readingmodule/questions',
            answerResponse: 'writtingmodule/AnswerResponse'
        }),
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        showModal() {
            this.dictText= this.selectedquestion.msc_desc
            this.isShowModal = true;
            this.$root.$emit('bv::show::modal', 'dictionery-Modal')
            this.$refs.timer.pauseTime()
            },
        hideModal() {
            this.isShowModal = false
            this.$refs.timer.resumeTime()
            },
        searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].mcs_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        updateSelectQuestionStatus(question,id){
            ////console.log(this.selectedquestion)
            this.selectedquestion = question
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.mcs_id==id)
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            ////console.log(question)
        },
        getQuestionDetail(questionId){
             if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            this.reset()
            this.currentQuestion=questionId
            this.userAnswer.mcs_id = questionId;
            this.userAnswer.user_score=''
            this.checkAnswerFlag= false
            this.selectedquestion = this.questionLists.filter(function (item) {
                    return item.mcs_id == questionId;
                })[0];
            if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
                 //console.log(this.selectedquestion)
            this.modelTitle = this.selectedquestion.title
            this.questionservice('configmultiplechoicechild/id='+questionId).then(()=>{
            this.answerLists = this.getQuestionList().data
            //console.log(this.answerLists)
            this.actualAnswer =this.answerLists.filter((item)=>{
                return item.correct_val_ind == "T"
            })[0]
            })
            this.$nextTick(function(){
            this.$refs.discussionTab.updateQuestionId(questionId)
            this.$refs.timer.setTime(0)
            })
            this.removeClasses()
        },
        toggle: function(todo){
                //console.log('Inside toggle')
                for(var i = 0; i < this.answerLists.length; i++){
                this.answerLists[i].done = false;
                }
                todo.done = !todo.done
                $('li.wrongAnswer').removeClass('wrongAnswer')
                $('li.rightAnswer').removeClass('rightAnswer')
        },
        async checkAnswer(){
            var vm = this;
            vm.score_count=0
            vm.correctAnswer= vm.answerLists.filter((item)=>{
                return item.correct_val_ind == "T"
            })[0];
            vm.currentAnswer= vm.answerLists.filter((item)=>{
                return item.done == true
            })[0];
            
            vm.correctAnswer['done'] = true
            $('#'+vm.correctAnswer['mcsc_id']).addClass('rightAnswer')
            if(vm.currentAnswer['mcsc_id']!= vm.correctAnswer['mcsc_id']){
                $('#'+vm.currentAnswer['mcsc_id']).addClass('wrongAnswer')
            }
            else{ 
                vm.score_count=1
                vm.userAnswer.user_score ='Your Score: 1/1';
            }
            
        },
        redoQuest(){
            this.getQuestionDetail(this.currentQuestion)
        },
        reset(){
            this.userAnswer={
                user_id:this.$ls.get('user').user_id,
                mcs_id:0,
                mcsc_id:0,
                url:'',
                mock_test_id:0,
                user_score:'' //if 0 then answer is not correct if 1 then answer is correct
            }
            this.answerLists={
                type: Object,
                required: true
            }
            this.answerSubmit = false
            this.userAnswer.user_score=''
            this.checkAnswerFlag= false
            this.actualAnswer = null
        },
        async submitanswer() {
            this.currentAnswer= this.answerLists.filter((item)=>{
                return item.done == true
            })[0];
            this.checkAnswerFlag = true;
            if(this.currentAnswer == null | this.currentAnswer =='') {
               //alert(this.currentAnswer)
                return this.answerSubmit = true
            }
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            await this.checkAnswer();
            var vm = this;
            
            if(vm.currentAnswer!=null){
            vm.userAnswer.mock_test_id=0
            vm.userAnswer.mcsc_id = vm.currentAnswer['mcsc_id'];
            vm.userAnswer.url = 'usermultiplechoiceanswer/createanswer';
            //console.log(vm.userAnswer)
            vm.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                    vm.userAnswerResponse = this.answerResponse().data
                    //alert('Data has been saved successfully.');
                    //vm.resetAll();
                });
            }
            this.answerSubmit = true
                
        },
        removeClasses(){
            $('li.wrongAnswer').removeClass('wrongAnswer')
            $('li.rightAnswer').removeClass('rightAnswer')
        },
        timerEnd(){
            //console.log("end of time")
        },
    },
    beforeMount(){
        this.questionservice('configmultiplechoice/getallmutipleoptions').then(()=>{
            this.questionLists = this.getQuestionList().data
            //this.userAnswer.user_id = this.userDetail().user_id
            //var userdata = LocalStorageService.getUser()
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            //console.log(this.questionLists)
        })
    }
}
</script>
<style scoped>
.text-center button{
    margin-right:30px
}


</style>